<template>
  <div class="box">
    <router-link
      v-if="$can('admin/member/create')"
      :to="{ path: 'admin/create' }"
    >
      <el-button
        type="primary"
        size="medium"
      >
        新建管理员
      </el-button>
    </router-link>

    <div class="box-toolbar">
      <div class="box-item">
        <el-input
          v-model="phone"
          placeholder="请输入号码"
          clearable
          @keyup.enter.native="searchGo"
        >
        </el-input>
      </div>
      <div class="box-item">
        <el-input
          placeholder="请输入用户名称"
          v-model="username"
          clearable
          @keyup.enter.native="searchGo"
        >
        </el-input>
      </div>
      <div class="box-item">
        <el-button
          icon="el-icon-search"
          circle
          @click="searchGo()"
        ></el-button>
      </div>
    </div>
    <el-table
      height="70vh"
      v-loading="loading"
      border
      :stripe="true"
      :data="lists"
    >
      <el-table-column
        width="90"
        class-name="text-mono"
        prop="id"
        label="Id"
      >
      </el-table-column>
      <el-table-column
        width="150"
        prop="phone"
        class-name="text-mono"
        label="电话号码"
      >
      </el-table-column>
      <el-table-column
        width="150"
        prop="username"
        label="昵称"
      >
      </el-table-column>
      <el-table-column
        width="150"
        prop="name"
        label="描述名"
      >
      </el-table-column>
      <el-table-column
        width="60"
        align="center"
        label="头像"
      >
        <template slot-scope="scope">
          <bp-avatar :avatar="scope.row.avatar"></bp-avatar>
        </template>
      </el-table-column>
      <el-table-column label="权限等级">
        <template slot-scope="scope">
          {{scope.row.level ? scope.row.level.level +'级' : '无'}}
        </template>
      </el-table-column>

      <el-table-column
        prop="last_time"
        label="上次登录时间"
      >
      </el-table-column>
      <el-table-column
        prop="now_time"
        label="当前登录时间"
      >
      </el-table-column>
      <el-table-column
        width="190"
        label="操作"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-dropdown trigger="click">
            <el-button
              plain
              size="mini"
              type="primary"
            >
              操作<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-if="$can('admin/member/edit')"
                @click.native="handleModelEdit(scope.row)"
              >
                编辑
              </el-dropdown-item>
              <el-dropdown-item
                v-if="$can('admin/member/delete')"
                @click.native="handleModelDelete(scope.row)"
              >
                删除
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <page
      :pageSize="limit"
      :count="count"
      @change="pageChange"
      @changeSize="changeSize"
    >
    </page>
  </div>
</template>

<script>
import AdminService from '@admin/services/AdminService'
import BpAvatar from '@admin/components/Avatar'
import Page from '@admin/components/Pagination'
import pagination from '@admin/mixins/PaginationMixin'

export default {
  components: { BpAvatar, Page },
  data () {
    return {
      loading: true,
      function: function (param) {
        return AdminService.all(param)
      },
      delete (param) {
        return AdminService.delete(param)
      },
      phone: '',
      username: '',
      filterArrayLists: ['id', 'phone', 'username', 'name', 'avatar', 'level', 'last_time', 'now_time'],
      tHeaderFather: ['ID', '电话号码', '昵称', '描述名', '头像', '权限等级', '上次登录时间', '当前登录时间']
    }
  },
  mixins: [pagination],
  methods: {
    async searchGo () {
      this.page = 1
      let param = {
        username: this.username,
        phone: this.phone
      }
      this.where = param
      await this.getList(param)
    }
  }
}
</script>
